/* eslint-disable prettier/prettier */
import firebase from 'firebase';

const firebaseConfig = {
  apiKey: "AIzaSyDjY0xZlrATpQ4PumxI2mIysoYfQaeQcLw",
  authDomain: "geocementlive.firebaseapp.com",
  projectId: "geocementlive",
  storageBucket: "geocementlive.appspot.com",
  messagingSenderId: "1013252562443",
  appId: "1:1013252562443:web:9b3b6441d9a7155ec8b10e",
  measurementId: "G-D4NS5CQD63"
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
const database = firebaseApp.firestore();
const auth = firebase.auth();
const firebaseStorage = firebase.storage()

export { database, auth, firebaseStorage, firebase };
